@import './../../style/_theme.scss';
@import './../../style/_mixins.scss';

.Jersey {
  .JerseyBase {
    &__Pattern {
      &__Plain {
        background-color: var(--color1);
      }

      &__VerticalStripes {
        background-size: calc(var(--unit) * 2);
        background-image: 
          linear-gradient(
            90deg,
            var(--color1) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 1.5),
            var(--color1) calc(var(--unit) * 1.5)
          )
        ;
        background-position: center;
      }

      &__HorizontalStripes {
        background-size: calc(var(--unit) * 2) calc(var(--unit) * 2);
        background-image: 
          linear-gradient(
            var(--color1) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 1.5),
            var(--color1) calc(var(--unit) * 1.5)
          )
        ;
        background-position: center;
      }

      &__ObliqueStripes {
        background-image: 
          repeating-linear-gradient(
            45deg,
            var(--color1),
            var(--color1) var(--unit),
            var(--color2) var(--unit),
            var(--color2) calc(var(--unit) * 2)
          )
        ;
        background-position: center;
      }

      &__VerticalBand {
        background-image: 
          linear-gradient(
            90deg,
            var(--color1) calc(50% - 5px - var(--unit)),
            var(--color2) calc(50% - 5px - var(--unit)),
            var(--color2) calc(50% + 5px + var(--unit)),
            var(--color1) calc(50% + 5px + var(--unit))
          )
        ;
        background-position: center;
      }

      &__HorizontalBand {
        background-image: 
          linear-gradient(
            var(--color1) calc(50% - 20px - var(--unit)),
            var(--color2) calc(50% - 20px - var(--unit)),
            var(--color2) calc(50% + 20px + var(--unit)),
            var(--color1) calc(50% + 20px + var(--unit))
          )
        ;
        background-position: center;
      }

      &__ObliqueBand {
        background-image: 
          linear-gradient(
            -60deg,
            var(--color1) calc(50% - 5px - var(--unit)),
            var(--color2) calc(50% - 5px - var(--unit)),
            var(--color2) calc(50% + 5px + var(--unit)),
            var(--color1) calc(50% + 5px + var(--unit))
          )
        ;
        background-position: center;
      }

      &__ObliqueBandInverted {
        background-image: 
          linear-gradient(
            60deg,
            var(--color1) calc(50% - 5px - var(--unit)),
            var(--color2) calc(50% - 5px - var(--unit)),
            var(--color2) calc(50% + 5px + var(--unit)),
            var(--color1) calc(50% + 5px + var(--unit))
          )
        ;
        background-position: center;
      }

      &__VerticalHalves {
        background-image: 
          linear-gradient(
            90deg,
            var(--color1) calc(50% - (var(--unit) * 2) + (var(--unit-base) * 2)),
            var(--color2) calc(50% - (var(--unit) * 2) + (var(--unit-base) * 2))
          )
        ;
      }

      &__HorizontalHalves {
        background-image: 
          linear-gradient(
            var(--color1) calc(50% - (var(--unit) * 5) + (var(--unit-base) * 5)),
            var(--color2) calc(50% - (var(--unit) * 5) + (var(--unit-base) * 5))
          )
        ;
      }

      &__ObliqueHalves {
        background-image: 
          linear-gradient(
            45deg,
            var(--color1) calc(50% - (var(--unit) * 4) + (var(--unit-base) * 4)),
            var(--color2) calc(50% - (var(--unit) * 4) + (var(--unit-base) * 4))
          )
        ;
      }

      &__VerticalZigZagHalves {
        background-size: calc(var(--unit) * 2) 100%;
        background-image:
          conic-gradient(
            var(--color1) 45deg,
            var(--color2) 45deg,
            var(--color2) 315deg,
            var(--color1) 315deg
          )
        ;
        background-position: center;
      }

      &__HorizontalZigZagHalves {
        background-size: 100% calc(var(--unit) * 2);
        background-image:
          conic-gradient(
            from 0 at calc(50% - var(--unit) / 2) 50%,
            var(--color1) 45deg,
            var(--color2) 45deg,
            var(--color2) 135deg,
            var(--color1) 135deg
          )
        ;
        background-position: center;
      }

      &__Checkboard {
        background-size: calc(var(--unit) * 3) calc(var(--unit) * 3);
        background-image: 
          conic-gradient(
            var(--color1) 90deg,
            var(--color2) 90deg,
            var(--color2) 180deg,
            var(--color1) 180deg,
            var(--color1) 270deg,
            var(--color2) 270deg
          )
        ;
        background-position: center;
      }

      &__TiltedCheckboard {
        background-size: calc(var(--unit) * 3) calc(var(--unit) * 3);
        background-image:
          conic-gradient(
            var(--color1) 45deg,
            var(--color2) 45deg,
            var(--color2) 135deg,
            var(--color1) 135deg,
            var(--color1) 225deg,
            var(--color2) 225deg,
            var(--color2) 315deg,
            var(--color1) 315deg,
          )
        ;
        background-position: center;
      }

      &__Chevron {
        background-color: var(--color2);
        background-size: 100%;
        background-image:
          conic-gradient(
            from 0 at 50% 120px,
            var(--color1) 45deg,
            var(--color3) 45deg,
            var(--color3) 315deg,
            var(--color1) 315deg
          ),
          conic-gradient(
            from 0 at 50% calc(120px + var(--unit) * 2),
            var(--color3) 45deg,
            var(--color1) 45deg,
            var(--color1) 315deg,
            var(--color3) 315deg
          ),
        ;
        background-position: 0 0, 0 20px;
        background-repeat: no-repeat;
      }

      &__ZigZag {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 4) calc(var(--unit) * 4);
        background-image:
          conic-gradient(
            var(--color2) 45deg,
            var(--color3) 45deg,
            var(--color3) 315deg,
            var(--color2) 315deg
          ),
          linear-gradient(
            45deg,
            var(--color2) 25%, 
            var(--color3) 25%
          ),
          linear-gradient(
            315deg,
            var(--color2) 25%,
            var(--color3) 25%
          )
        ;
        background-position: center;
      }

      &__PolkaDots {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 2) calc(var(--unit) * 2);
        background-image: 
          radial-gradient(
            circle at 0 0,
            var(--color2) calc(var(--unit) / 3),
            var(--color3) calc(var(--unit) / 3)
          ),
          radial-gradient(
            circle at 0 100%,
            var(--color2) calc(var(--unit) / 3),
            var(--color3) calc(var(--unit) / 3)
          ),
          radial-gradient(
            circle at 100% 0,
            var(--color2) calc(var(--unit) / 3),
            var(--color3) calc(var(--unit) / 3)
          ),
          radial-gradient(
            circle at 100% 100%,
            var(--color2) calc(var(--unit) / 3),
            var(--color3) calc(var(--unit) / 3)
          ),
          radial-gradient(
            circle at 50% 50%,
            var(--color2) calc(var(--unit) / 3),
            var(--color3) calc(var(--unit) / 3)
          )
        ;
        background-position: center;
      }

      &__Ipanema {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 3) calc(var(--unit) * 2);
        background-image: 
          radial-gradient(
            circle at bottom center,
            var(--color3) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 1),
            var(--color3) calc(var(--unit) * 1)
          ),
          radial-gradient(
            circle at top left,
            var(--color3) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 1),
            var(--color3) calc(var(--unit) * 1)
          ),
          radial-gradient(
            circle at top right,
            var(--color3) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 1),
            var(--color3) calc(var(--unit) * 1)
          )
        ;
        background-position: center;
      }

      &__Stitches {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 1) calc(var(--unit) * 1);
        background-position: calc(var(--unit) * -0.5) 0, 0 calc(var(--unit) * 0.75), 0 0, calc(var(--unit) * -0.75) calc(var(--unit) * 0.5);
        background-image: 
          conic-gradient(
            from 0 at calc(var(--unit) * 0.5) calc(var(--unit) * 0.25),
            var(--color2) 90deg,
            var(--color3) 90deg
          ),
          conic-gradient(
            from 0 at calc(var(--unit) * 0.5) calc(var(--unit) * 0.25),
            var(--color2) 90deg,
            var(--color3) 90deg
          ),
          conic-gradient(
            from 0 at calc(var(--unit) * 0.75) calc(var(--unit) * 0.5),
            var(--color2) 90deg,
            var(--color3) 90deg
          ),
          conic-gradient(
            from 0 at calc(var(--unit) * 0.75) calc(var(--unit) * 0.5),
            var(--color2) 90deg,
            var(--color3) 90deg
          )
        ;
      }

      &__Lightning {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 3) calc(var(--unit) * 2);
        background-image: 
          repeating-linear-gradient(
            45deg,
            var(--color2) 0%,
            var(--color2) 15%,
            var(--color3) 15%,
            var(--color3) 30%
          )
        ;
        background-position: center;
      }

      &__BarbedWire {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 1.25) calc(var(--unit) * 1.25);
        background-position: calc(var(--unit) * 0.5) 0;
        background-image:
          repeating-linear-gradient(
            225deg,
            var(--color3) 0px,
            var(--color3) calc(var(--unit) * 0.375),
            var(--color2) calc(var(--unit) * 0.375),
            var(--color2) calc(var(--unit) * 0.7)
          )        
        ;
        background-position: center;
      }

      &__Mammatus {
        background-size: calc(var(--unit) * 2) calc(var(--unit) * 2);
        background-position: calc(var(--unit) * 1) calc(var(--unit) * 1), 0 0, 0 0;
        background-image:
          radial-gradient(
            circle at top center,
            var(--color1) calc(var(--unit) * 1),
            var(--color3) calc(var(--unit) * 1)
          ),
          radial-gradient(
            circle at top center,
            var(--color2) calc(var(--unit) * 1),
            var(--color3) calc(var(--unit) * 1)
          ),
          linear-gradient(
            var(--color1) calc(var(--unit) * 1),
            var(--color2) calc(var(--unit) * 1)
          )
        ;
      }

      &__Rain {
        --unit-local: var(--unit) / 4;
        background-color: var(--color1);
        background-size: calc(var(--unit-local) * 2.4) calc(var(--unit-local) * 3.2);
        background-position: calc(var(--unit-local) * -2) calc(var(--unit-local) * -2), calc(var(--unit-local) * -0.8) calc(var(--unit-local) * -0.4), calc(var(--unit-local) * 0.2) calc(var(--unit-local) * 0.2), calc(var(--unit-local) * 0.2) calc(var(--unit-local) * 1.2), calc(var(--unit-local) * -1) calc(var(--unit-local) * 1.8), calc(var(--unit-local) * -1) calc(var(--unit-local) * -0.4);
        background-image:
          conic-gradient(
            from 0 at calc(var(--unit-local) * 2) calc(var(--unit-local) * 2.2),
            var(--color3) 90deg,
            var(--color2) 90deg,
            var(--color2) 180deg,
            var(--color3) 180deg
          ),
          conic-gradient(
            from 0 at calc(var(--unit-local) * 2) calc(var(--unit-local) * 2.2),
            var(--color3) 90deg,
            var(--color2) 90deg,
            var(--color2) 180deg,
            var(--color3) 180deg
          ),
          radial-gradient(
            circle at center center,
            var(--color2) calc(var(--unit-local) * 0.2),
            var(--color3) calc(var(--unit-local) * 0.2)
          ),
          radial-gradient(
            circle at center center,
            var(--color2) calc(var(--unit-local) * 0.2),
            var(--color3) calc(var(--unit-local) * 0.2)
          ),
          radial-gradient(
            circle at center center,
            var(--color2) calc(var(--unit-local) * 0.2),
            var(--color3) calc(var(--unit-local) * 0.2)
          ),
          radial-gradient(
            circle at center center,
            var(--color2) calc(var(--unit-local) * 0.2),
            var(--color3) calc(var(--unit-local) * 0.2)
          )
        ;
      }

      &__Waves {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 2) calc(var(--unit) * 2);
        background-image: 
          radial-gradient(
            circle at right bottom,
            var(--color3) calc(var(--unit) * 0.6),
            var(--color2) calc(var(--unit) * 0.6),
            var(--color2) calc(var(--unit) * 1.2),
            var(--color3) calc(var(--unit) * 1.2)
          ),
          radial-gradient(
            circle at left top,
            var(--color3) calc(var(--unit) * 0.8),
            var(--color2) calc(var(--unit) * 0.8),
            var(--color2) calc(var(--unit) * 1.4),
            var(--color3) calc(var(--unit) * 1.4)
          )
        ;
        background-position: center;
      }

      &__VerticalThinLines {
        background-color: var(--color1);
        background-size: var(--unit);
        background-image:
          linear-gradient(
            90deg,
            var(--color3) calc(50% - 1px),
            var(--color2) calc(50% - 1px),
            var(--color2) calc(50% + 1px),
            var(--color3) calc(50% + 1px)
          )
        ;
        background-position: center;
      }

      &__HorizontalThinLines {
        background-color: var(--color1);
        background-size: var(--unit) var(--unit);
        background-image:
          linear-gradient(
            0deg,
            var(--color3) calc(50% - 1px),
            var(--color2) calc(50% - 1px),
            var(--color2) calc(50% + 1px),
            var(--color3) calc(50% + 1px)
          )
        ;
        background-position: center;
      }

      &__ObliqueThinLines {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 1.5) calc(var(--unit) * 1.5);
        background-image:
          linear-gradient(
            45deg,
            var(--color2) calc(1px),
            var(--color3) calc(1px),
            var(--color3) calc(50% - 1px),
            var(--color2) calc(50% - 1px),
            var(--color2) calc(50% + 1px),
            var(--color3) calc(50% + 1px),
            var(--color3) calc(100% - 1px),
            var(--color2) calc(100% - 1px)
          )
        ;
        background-position: center;
      }

      &__Fade {
        background-size: calc(var(--unit) * 2);
        background-image:
          linear-gradient(
            var(--color1) 40%,
            var(--color2) 80%
          )
        ;
        background-position: center;
      }
        
      &__Radial {
        background-image: 
          repeating-radial-gradient(
            circle at 50% 50%,
            var(--color1),
            var(--color1) var(--unit),
            var(--color2) var(--unit),
            var(--color2) calc(var(--unit) * 2)
          )
        ;
      }

      &__Mesh {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 2) calc(var(--unit) * 2);
        background-image:
          linear-gradient(
            -45deg,
            var(--color2) 0%,
            var(--color2) 5%,
            var(--color3) 5%,
            var(--color3) 20%,
            var(--color2) 20%,
            var(--color2) 30%,
            var(--color3) 30%,
            var(--color3) 70%,
            var(--color2) 70%,
            var(--color2) 80%,
            var(--color3) 80%,
            var(--color3) 95%,
            var(--color2) 95%,
            var(--color2) 100%
          ),
          linear-gradient(
            45deg,
            var(--color2) 0%,
            var(--color2) 5%,
            var(--color3) 5%,
            var(--color3) 20%,
            var(--color2) 20%,
            var(--color2) 30%,
            var(--color3) 30%,
            var(--color3) 70%,
            var(--color2) 70%,
            var(--color2) 80%,
            var(--color3) 80%,
            var(--color3) 95%,
            var(--color2) 95%,
            var(--color2) 100%
          )
        ;
        background-position: center;
      }

      &__PlainChest {
        background-image:
          radial-gradient(
            circle at 50% 100%,
            var(--color3) 260px,
            var(--color1) 260px
          ),
          repeating-linear-gradient(
            90deg,
            var(--color2) 0,
            var(--color2) calc(var(--unit) / 2),
            var(--color1) calc(var(--unit) / 2),
            var(--color1) calc(var(--unit) / 1)
          )
        ;
        background-position: center;
      }

      &__Transition {
        background-color: var(--color1);
        background-image:
          linear-gradient(
            -45deg,
            var(--color2) calc(50% - var(--unit) * 2),
            var(--color3) calc(50% - var(--unit) * 2),
            var(--color3) calc(50% + var(--unit) * 2),
            var(--color1) calc(50% + var(--unit) * 2)
          ),
          repeating-linear-gradient(
            45deg,
            var(--color2) 0,
            var(--color2) calc(var(--unit) / 6),
            var(--color1) calc(var(--unit) / 6),
            var(--color1) calc(var(--unit) / 3)
          )
        ;
        background-position: center;
      }

      &__DoubleStripes {
        background-size: calc(var(--unit) * 3) 10px;
        background-image:
          linear-gradient(
            var(--color1) 8px,
            var(--color3) 8px,
          ),
          linear-gradient(
            90deg,
            var(--color1) calc(var(--unit) * 0.5 * 1.5),
            var(--color2) calc(var(--unit) * 0.5 * 1.5),
            var(--color2) calc(var(--unit) * 1.5 * 1.5),
            var(--color1) calc(var(--unit) * 1.5 * 1.5)
          )
        ;
        background-position: center;
      }

      &__Pallets {
        background-size: calc(var(--unit) * 2) 20px;
        background-image:
          linear-gradient(
            var(--color1) 40%,
            var(--color3) 40%
          ),
          linear-gradient(
            var(--color2) 16px,
            var(--color3) 16px,
          ),
          linear-gradient(
            90deg,
            var(--color2) calc(var(--unit) * 0.5),
            var(--color1) calc(var(--unit) * 0.5),
            var(--color1) calc(var(--unit) * 1.5),
            var(--color2) calc(var(--unit) * 1.5)
          )
        ;
        background-position: center;
      }

      &__DoubleLine {
        background-image:
          linear-gradient(
            90deg,
            var(--color2) calc(50% - var(--unit)),
            var(--color1) calc(50% - var(--unit)),
            var(--color1) 50%,
            var(--color2) 50%,
            var(--color2) calc(50% + var(--unit)),
            var(--color1) calc(50% + var(--unit))
          )
        ;
        background-position: center;
      }

      &__Cross {
        background-image:
          linear-gradient(
            var(--color3) calc(35% - var(--unit) * 0.8),
            var(--color2) calc(35% - var(--unit) * 0.8),
            var(--color2) calc(35% + var(--unit) * 0.8),
            var(--color3) calc(35% + var(--unit) * 0.8)
          ),
          linear-gradient(
            90deg,
            var(--color1) calc(50% - var(--unit) * 0.8),
            var(--color2) calc(50% - var(--unit) * 0.8),
            var(--color2) calc(50% + var(--unit) * 0.8),
            var(--color1) calc(50% + var(--unit) * 0.8)
          )
        ;
        background-position: center;
      }

      &__Collar {
        background-color: var(--color1);
        background-image: 
          radial-gradient(
            circle at 50% 0,
            var(--color2) 120px,
            var(--color1) 120px,
            var(--color1) calc(120px + var(--unit) * 4),
            var(--color2) calc(120px + var(--unit) * 4),
          )
        ;
        background-position: center;
      }

      &__Crosses {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 6) calc(var(--unit) * 6);
        background-image: 
          repeating-linear-gradient(
            45deg,
            var(--color3) 0%,
            var(--color3) 80%,
            var(--color1) 80%,
            var(--color1) 100%
          ),
          repeating-linear-gradient(
            -45deg,
            var(--color3) 0%,
            var(--color3) 80%,
            var(--color1) 80%,
            var(--color1) 100%
          ),
          repeating-linear-gradient(
            135deg,
            var(--color3) 0%,
            var(--color3) 80%,
            var(--color1) 80%,
            var(--color1) 100%
          ),
          repeating-linear-gradient(
            -135deg,
            var(--color3) 0%,
            var(--color3) 80%,
            var(--color1) 80%,
            var(--color1) 100%
          ),
          repeating-linear-gradient(
            45deg, 
            var(--color3) 0%,
            var(--color3) 40%,
            var(--color2) 40%,
            var(--color2) 60%,
            var(--color3) 60%, 
            var(--color3) 100%
          ), 
          repeating-linear-gradient(
            -45deg,
            var(--color3) 0%,
            var(--color3) 40%, 
            var(--color2) 40%, 
            var(--color2) 60%, 
            var(--color3) 60%, 
            var(--color3) 100%
          )
        ;
        background-position: center;
      }

      &__SequentialFade {
        background-size: calc(var(--unit) * 3) calc(var(--unit) * 3);
        background-image:
          linear-gradient(
            var(--color1) 0,
            var(--color2) calc(var(--unit) * 3),
          )
        ;
        background-position: center;
      }

      &__Lifeline {
        background-size: calc(var(--unit) * 2) 100%;
        background-image:
          conic-gradient(
            at 50% 45%,
            var(--color3) 45deg,
            var(--color1) 45deg,
            var(--color1) 315deg,
            var(--color3) 315deg
          ),
          conic-gradient(
            at 50% 40%,
            var(--color1) 45deg,
            var(--color2) 45deg,
            var(--color2) 315deg,
            var(--color1) 315deg
          ),
        ;
        background-position: center;
      }

      &__Flames {
        background-color: var(--color2);
        background-size: var(--unit) 100%;
        background-image:
          linear-gradient(
            to right top,
            var(--color3) 50%,
            var(--color1) 50%,
          ),
          linear-gradient(
            to left top,
            var(--color3) 50%,
            var(--color1) 50%,
          )
        ;
        background-position: center;
      }

      &__Stars {
        background-color: var(--color2);
        background-size: calc(var(--unit) * 2) calc(var(--unit) * 2);
        background-image:
          radial-gradient(
            circle at 0 0,
            var(--color1) calc(var(--unit) * 1.1),
            var(--color3) calc(var(--unit) * 1.1),
          ),
          radial-gradient(
            circle at 0 100%,
            var(--color1) calc(var(--unit) * 1.1),
            var(--color3) calc(var(--unit) * 1.1),
          ),
          radial-gradient(
            circle at 100% 0,
            var(--color1) calc(var(--unit) * 1.1),
            var(--color3) calc(var(--unit) * 1.1),
          ),
          radial-gradient(
            circle at 100% 100%,
            var(--color1) calc(var(--unit) * 1.1),
            var(--color3) calc(var(--unit) * 1.1),
          )
        ;
        background-position: center;
      }

      &__Diamonds {
        background-color: var(--color1);
        background-size: 100% calc(var(--unit) * 1);
        background-image:
          conic-gradient(
            at calc(var(--unit) * 4) 0,
            var(--color3) 45deg,
            var(--color1) 45deg,
            var(--color1) 135deg,
            var(--color3) 135deg
          ),
          conic-gradient(
            at calc(var(--unit) * 4) 100%,
            var(--color3) 45deg,
            var(--color1) 45deg,
            var(--color1) 135deg,
            var(--color3) 135deg
          ),
          conic-gradient(
            at calc(var(--unit) * 3.5) 50%,
            var(--color3) 45deg,
            var(--color2) 45deg,
            var(--color2) 135deg,
            var(--color3) 135deg
          ),
          conic-gradient(
            at calc(var(--unit) * 3) 0,
            var(--color3) 45deg,
            var(--color1) 45deg,
            var(--color1) 135deg,
            var(--color3) 135deg
          ),
          conic-gradient(
            at calc(var(--unit) * 3) 100%,
            var(--color3) 45deg,
            var(--color1) 45deg,
            var(--color1) 135deg,
            var(--color3) 135deg
          ),
          conic-gradient(
            at calc(var(--unit) * 2.5) 50%,
            var(--color3) 45deg,
            var(--color2) 45deg,
            var(--color2) 135deg,
            var(--color3) 135deg
          ),
        ;
        background-position: center;
      }

      &__Patchwork {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 12) calc(var(--unit) * 12);
        background-image:
          conic-gradient(
            var(--color3) 90deg,
            var(--color1) 90deg,
            var(--color1) 180deg,
            var(--color2) 180deg,
            var(--color2) 270deg,
            var(--color1) 270deg,
            var(--color1) 300deg
          ),
          repeating-linear-gradient(
            45deg,
            var(--color2) 0%,
            var(--color2) 2%,
            var(--color3) 2%,
            var(--color3) 4%
          )
        ;
        background-position: center;
      }

      &__Burst {
        background-color: var(--color1);
        background-size: 100% 100%;
        background-image:
          conic-gradient(
            at 50% 0, 
            var(--color3),
            var(--color3) 10deg,
            var(--color2) 10deg,
            var(--color2) 20deg,
            var(--color3) 20deg,
            var(--color3) 30deg,
            var(--color2) 30deg,
            var(--color2) 40deg,
            var(--color3) 40deg,
            var(--color3) 50deg,
            var(--color2) 50deg,
            var(--color2) 60deg,
            var(--color3) 60deg,
            var(--color3) 70deg,
            var(--color2) 70deg,
            var(--color2) 80deg,
            var(--color3) 80deg,
            var(--color3) 90deg,
            var(--color2) 90deg,
            var(--color2) 100deg,
            var(--color3) 100deg,
            var(--color3) 110deg,
            var(--color2) 110deg,
            var(--color2) 120deg,
            var(--color3) 120deg,
            var(--color3) 130deg,
            var(--color2) 130deg,
            var(--color2) 140deg,
            var(--color3) 140deg,
            var(--color3) 150deg,
            var(--color2) 150deg,
            var(--color2) 160deg,
            var(--color3) 160deg,
            var(--color3) 170deg,
            var(--color2) 170deg,
            var(--color2) 180deg,
            var(--color3) 180deg,
            var(--color3) 190deg,
            var(--color2) 190deg,
            var(--color2) 200deg,
            var(--color3) 200deg,
            var(--color3) 210deg,
            var(--color2) 210deg,
            var(--color2) 220deg,
            var(--color3) 220deg,
            var(--color3) 230deg,
            var(--color2) 230deg,
            var(--color2) 240deg,
            var(--color3) 240deg,
            var(--color3) 250deg,
            var(--color2) 250deg,
            var(--color2) 260deg,
            var(--color3) 260deg,
            var(--color3) 270deg,
            var(--color2) 270deg,
            var(--color2) 280deg,
            var(--color3) 280deg,
            var(--color3) 290deg,
            var(--color2) 290deg,
            var(--color2) 300deg,
            var(--color3) 300deg,
            var(--color3) 310deg,
            var(--color2) 310deg,
            var(--color2) 320deg,
            var(--color3) 320deg,
            var(--color3) 330deg,
            var(--color2) 330deg,
            var(--color2) 340deg,
            var(--color3) 340deg,
            var(--color3) 350deg,
            var(--color2) 350deg,
            var(--color2) 360deg
          )
        ;
      }

      &__HalfCircles {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 4) calc(var(--unit) * 2);
        background-image: 
          radial-gradient(
            circle at bottom center,
            var(--color3),
            var(--color3) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 1),
            var(--color3) calc(var(--unit) * 1),
            var(--color3)
          ),
          radial-gradient(
            circle at top left,
            var(--color3),
            var(--color3) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 1),
            var(--color3) calc(var(--unit) * 1),
            var(--color3)
          ),
          radial-gradient(
            circle at top right,
            var(--color3),
            var(--color3) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 1),
            var(--color3) calc(var(--unit) * 1),
            var(--color3)
          )
        ;
        background-position: center;
      }

      &__BigInJapan {
        background-color: var(--color1);
        background-image: 
          radial-gradient(
            circle at 50% 40%,
            var(--color2) calc(var(--unit) * 1.5),
            var(--color3) calc(var(--unit) * 1.5)
          ),
          conic-gradient(
            at 50% 40%,
            var(--color3),
            var(--color3) 10deg,
            var(--color2) 10deg,
            var(--color2) 20deg,
            var(--color3) 20deg,
            var(--color3) 30deg,
            var(--color2) 30deg,
            var(--color2) 40deg,
            var(--color3) 40deg,
            var(--color3) 50deg,
            var(--color2) 50deg,
            var(--color2) 60deg,
            var(--color3) 60deg,
            var(--color3) 70deg,
            var(--color2) 70deg,
            var(--color2) 80deg,
            var(--color3) 80deg,
            var(--color3) 90deg,
            var(--color2) 90deg,
            var(--color2) 100deg,
            var(--color3) 100deg,
            var(--color3) 110deg,
            var(--color2) 110deg,
            var(--color2) 120deg,
            var(--color3) 120deg,
            var(--color3) 130deg,
            var(--color2) 130deg,
            var(--color2) 140deg,
            var(--color3) 140deg,
            var(--color3) 150deg,
            var(--color2) 150deg,
            var(--color2) 160deg,
            var(--color3) 160deg,
            var(--color3) 170deg,
            var(--color2) 170deg,
            var(--color2) 180deg,
            var(--color3) 180deg,
            var(--color3) 190deg,
            var(--color2) 190deg,
            var(--color2) 200deg,
            var(--color3) 200deg,
            var(--color3) 210deg,
            var(--color2) 210deg,
            var(--color2) 220deg,
            var(--color3) 220deg,
            var(--color3) 230deg,
            var(--color2) 230deg,
            var(--color2) 240deg,
            var(--color3) 240deg,
            var(--color3) 250deg,
            var(--color2) 250deg,
            var(--color2) 260deg,
            var(--color3) 260deg,
            var(--color3) 270deg,
            var(--color2) 270deg,
            var(--color2) 280deg,
            var(--color3) 280deg,
            var(--color3) 290deg,
            var(--color2) 290deg,
            var(--color2) 300deg,
            var(--color3) 300deg,
            var(--color3) 310deg,
            var(--color2) 310deg,
            var(--color2) 320deg,
            var(--color3) 320deg,
            var(--color3) 330deg,
            var(--color2) 330deg,
            var(--color2) 340deg,
            var(--color3) 340deg,
            var(--color3) 350deg,
            var(--color2) 350deg,
            var(--color2) 360deg
          )
        ;
      }

      &__CompoundFade {
        background-size: calc(var(--unit) * 2);
        background-image:
          linear-gradient(
            90deg,
            var(--color1) calc(var(--unit) * 0.5),
            var(--color3) calc(var(--unit) * 0.5),
            var(--color3) calc(var(--unit) * 1.5),
            var(--color1) calc(var(--unit) * 1.5)
          ),
          linear-gradient(
            var(--color1) 40%,
            var(--color2) 80%
          )
        ;
        background-position: center;
      }

      &__Slanted {
        background-color: var(--color1);
        background-size: calc(var(--unit) * 2) calc(var(--unit) * 2);
        background-position: center;
        background-image:
          conic-gradient(
            from 0 at calc(var(--unit) * 1) 0,
            var(--color3) 225deg,
            var(--color2) 225deg,
            var(--color2) 270deg,
            var(--color3) 270deg
          ),
          conic-gradient(
            from 0 at calc(var(--unit) * 1) calc(var(--unit) * 2),
            var(--color3) 45deg,
            var(--color2) 45deg,
            var(--color2) 90deg,
            var(--color3) 90deg
          ),
          conic-gradient(
            from 0 at calc(var(--unit) * 1) calc(var(--unit) * 1),
            var(--color3) 45deg,
            var(--color2) 45deg,
            var(--color2) 90deg,
            var(--color3) 90deg,
            var(--color3) 225deg,
            var(--color2) 225deg,
            var(--color2) 270deg,
            var(--color3) 270deg
          )
        ;
      }

      &__Peaks {
        background-size: calc(var(--unit) * 1) calc(var(--unit) * 4);
        background-position: center center;
        background-image:
          conic-gradient(
            from 0 at 0 50%,
            var(--color1) 26.5deg,
            var(--color2) 26.5deg,
            var(--color2) 90deg,
            var(--color1) 90deg,
            var(--color1) 153.5deg,
            var(--color2) 153.5deg
          )
        ;
      }

      &__Triangles {
        $tilt: 30deg;
        $tilt2: 60deg;
        background-size: calc(var(--unit) * 2.9) calc(var(--unit) * 5);
        background-position: center center;
        background-image:
          conic-gradient(
            var(--color1) $tilt,
            var(--color2) $tilt,
            var(--color2) 90deg,
            var(--color1) 90deg,
            var(--color1) 90 + $tilt2,
            var(--color2) 90 + $tilt2,
            var(--color2) 180 + $tilt,
            var(--color1) 180 + $tilt,
            var(--color1) 270deg,
            var(--color2) 270deg,
            var(--color2) 270 + $tilt2,
            var(--color1) 270 + $tilt2
          )
        ;
      }

      &__Saw {
        background-size: calc(var(--unit) * 1.5) calc(var(--unit) * 2.5);
        background-position: center center;
        background-image:
          linear-gradient(
            30deg,
            var(--color1) 50%,
            var(--color2) 50%
          )
        ;
      }

      &__Equilibrium {
        background-position: center center;
        background-image:
          conic-gradient(
            from 0 at 50% 50%,
            var(--color1) 90deg,
            var(--color2) 90deg,
            var(--color2) 180deg,
            var(--color1) 180deg,
            var(--color1) 270deg,
            var(--color2) 270deg
          )
        ;
      }

      &__Basket {
        background-size: calc(var(--unit) * 2) calc(var(--unit) * 2);
        background-position: calc(50% - var(--unit) / 2) 50%;
        background-image:
          conic-gradient(
            from 0 at 50% 50%,
            var(--color3) 90deg,
            var(--color1) 90deg,
            var(--color1) 180deg,
            var(--color3) 180deg,
            var(--color3) 270deg,
            var(--color1) 270deg
          ),
          linear-gradient(
            var(--color2) 0,
            var(--color1) 50%,
            var(--color2) 50%,
            var(--color1) 100%
          )
        ;
      }

      &__Roadblock {
        background-position: center center;
        background-image:
          linear-gradient(
            var(--color1) calc(50% - (var(--unit) * 1.5)),
            var(--color3) calc(50% - (var(--unit) * 1.5)),
            var(--color3) calc(50% + (var(--unit) * 1.5)),
            var(--color1) calc(50% + (var(--unit) * 1.5))
          ),
          repeating-linear-gradient(
            45deg,
            var(--color1) 0,
            var(--color1) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 0.5),
            var(--color2) calc(var(--unit) * 1)
          )
        ;
      }

      &__Meme {
        background-size: calc(var(--unit) * 1.9) calc(var(--unit) * 1.9);
        background-position: center center;
        background-image:
          linear-gradient(
            var(--color3) calc((var(--unit) / 10) * 9),
            var(--color1) calc((var(--unit) / 10) * 9)
          ),
          linear-gradient(
            90deg,
            var(--color1) calc((var(--unit) / 10) * 1),
            var(--color2) calc((var(--unit) / 10) * 1),
            var(--color2) calc((var(--unit) / 10) * 2),
            var(--color1) calc((var(--unit) / 10) * 2),
            var(--color1) calc((var(--unit) / 10) * 3),
            var(--color2) calc((var(--unit) / 10) * 3),
            var(--color2) calc((var(--unit) / 10) * 4),
            var(--color1) calc((var(--unit) / 10) * 4),
            var(--color1) calc((var(--unit) / 10) * 5),
            var(--color2) calc((var(--unit) / 10) * 5),
            var(--color2) calc((var(--unit) / 10) * 6),
            var(--color1) calc((var(--unit) / 10) * 6),
            var(--color1) calc((var(--unit) / 10) * 7),
            var(--color2) calc((var(--unit) / 10) * 7),
            var(--color2) calc((var(--unit) / 10) * 8),
            var(--color1) calc((var(--unit) / 10) * 8),
            var(--color1) calc((var(--unit) / 10) * 9),
            var(--color2) calc((var(--unit) / 10) * 9),
            var(--color2) calc((var(--unit) / 10) * 10),
            var(--color1) calc((var(--unit) / 10) * 10),
            var(--color1) calc((var(--unit) / 10) * 11),
            var(--color3) calc((var(--unit) / 10) * 11)
          ),
          linear-gradient(
            var(--color2) calc((var(--unit) / 10) * 1),
            var(--color1) calc((var(--unit) / 10) * 1),
            var(--color1) calc((var(--unit) / 10) * 2),
            var(--color2) calc((var(--unit) / 10) * 2),
            var(--color2) calc((var(--unit) / 10) * 3),
            var(--color1) calc((var(--unit) / 10) * 3),
            var(--color1) calc((var(--unit) / 10) * 4),
            var(--color2) calc((var(--unit) / 10) * 4),
            var(--color2) calc((var(--unit) / 10) * 5),
            var(--color1) calc((var(--unit) / 10) * 5),
            var(--color1) calc((var(--unit) / 10) * 6),
            var(--color2) calc((var(--unit) / 10) * 6),
            var(--color2) calc((var(--unit) / 10) * 7),
            var(--color1) calc((var(--unit) / 10) * 7),
            var(--color1) calc((var(--unit) / 10) * 8),
            var(--color2) calc((var(--unit) / 10) * 8),
            var(--color2) calc((var(--unit) / 10) * 9),
            var(--color1) calc((var(--unit) / 10) * 9),
            var(--color1) calc((var(--unit) / 10) * 10)
          ),
        ;
      }

      &__Pines {
        background-size: calc(var(--unit) * 2) calc(var(--unit) * 3);
        background-position: center center;
        background-image:
          conic-gradient(
            from 0 at 50% 0,
            var(--color1) 153.5deg,
            var(--color3) 153.5deg,
            var(--color3) 206.5deg,
            var(--color1) 206.5deg
          ),
          linear-gradient(
            var(--color2) -20px,
            var(--color1) 100%
          )
        ;
      }
    }
  }
}
