@import './../../style/_theme.scss';
@import './../../style/_mixins.scss';

.Jersey {
  width: var(--width);
  height: var(--height);
  margin: 0 0 80px;
  padding: 0;
  position: relative;
  overflow: visible;

  & > * {
    width: var(--width);
    height: var(--height);
    position: absolute;
    top: 0;
    left: 0;
  }

  .Lock {
    width: 24px;
    height: 24px;
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    left: auto;
    pointer-events: none;

    &::after {
      content: var(--padlock-icon);
      width: 30px;
      height: 30px;
      display: block;
    }
  }

  &__Buttons {
    height: 40px;
    position: absolute;
    bottom: 0;
    top: auto;
    text-align: center;

    .Download {
      // transition: all $transition-fast ease-in-out;
      display: inline-block;
      overflow: hidden;
      width: 136px;
      height: 32px;
      border-radius: 20px;
      opacity: 0;
      margin: 0 2px;
      background-color: var(--control-background-active);
      color: var(--control-text-active);
      font-size: 12px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.1px;
      text-align: center;

      &:hover {
        box-shadow: var(--control-elevation);
        cursor: pointer;
      }
    }

    .Copy {
      // transition: all $transition-fast ease-in-out;
      display: inline-block;
      overflow: hidden;
      width: 136px;
      height: 32px;
      border-radius: 20px;
      opacity: 0;
      margin: 0 2px;
      background-color: var(--control-background-active);
      color: var(--control-text-active);
      font-size: 12px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.1px;
      text-align: center;

      &:hover {
        box-shadow: var(--control-elevation);
        cursor: pointer;
      }
    }

    .CopyNotice {
      // transition: all $transition-fast ease-in-out;
      display: inline-block;
      overflow: hidden;
      width: 160px;
      height: 32px;
      border-radius: 20px;
      color: var(--control-background-active);
      background-color: var(--control-text-active);
      font-size: 12px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.1px;
      text-align: center;
      position: absolute;
      top: -40px;
      left: 50%;
      margin-left: -80px;

      span {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
  }

  &__Selector {
    // transition: all 0.05s ease-in;
    width: 100%;
    height: 100%;
    appearance: none;
    border-radius: 8px;
    outline: none;
    padding: 8px;
    margin: -8px 0 0 -8px;
    box-sizing: content-box;
    border: 1px solid transparent;

    &:before {
      // transition: all 0.05s ease-in;
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background-color: var(--color1);
      border-radius: 8px;
      margin: -8px 0 0 -8px;
      opacity: 0;
    }
    
    &:after {
      // transition: all 0.05s ease-in;
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      border: 1px solid var(--color2);
      border-radius: 8px;
      margin: -8px 0 0 -8px;
      box-sizing: border-box;
      opacity: 0;
    }

    :hover > & {
      cursor: pointer;

      &::before {
        background-color: var(--selector-background);
        opacity: 1;
      }

      &::after {
        border-color: var(--selector-border);
        opacity: 1;
      }
    }

    &:checked {
      ~ .Lock {
        display: block;
      }

      &:before {
        background-color: var(--color1);
        opacity: 0.2;
      }

      &:after {
        border-color: var(--color1);
        opacity: 0.3;
      }
    }
  }

  .JerseyCuffs, 
  .JerseyHem, 
  .JerseyFolds, 
  .JerseySideShadows, 
  .JerseyShadows,
  .JerseySleeves,
  .JerseyShoulders,
  .JerseySide,
  .JerseyCollar {
    pointer-events: none;
  }

  .JerseyHem {
    opacity: 0.05;
  }

  .JerseyFolds, 
  .JerseySideShadows, 
  .JerseyShadows {
    clip-path: url(#tshirt);
  }

  .JerseyCollar {
    filter: drop-shadow(0 3px 3px rgba(0,0,0,0.2));
  }

  .JerseyBase {
    filter: drop-shadow(0 0 20px rgba(0,0,0,0.2));
    pointer-events: none;
  
    &__Pattern {
      pointer-events: none;
      clip-path: url(#tshirt);
      width: var(--width);
      height: var(--height);
      background-color: #FFFFFF;
    }
  }

  &:hover {
    .Download,
    .Copy {
      opacity: 1 !important;
    }
  }

  @media (max-width: $breakpoint-sm) {
    margin: 0 0 40px;
  }
}
