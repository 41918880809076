// ----------------------------------------------------------------
// THEME
// Design tokens for current theme
// ----------------------------------------------------------------


// Font Import
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=block');
@import url('https://fonts.googleapis.com/css2?family=Squada+One&display=block');


// Font Families
$font-sans: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
$font-display: 'Squada One', Impact, 'Arial Narrow', Arial, 'Helvetica Neue', Helvetica, sans-serif;


// Font assignation
$font-title: $font-display;
$font-text: $font-sans;
$font-accent: $font-sans;
$font-code: $font-sans;


// Font Weights
$font-weight-thin: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;


// Font Scale
$font-scale-core: 8px;
$font-scale-1: $font-scale-core * 13;           // 104
$font-scale-2: $font-scale-core * 9.5;          // 76
$font-scale-3: $font-scale-core * 8;            // 64
$font-scale-4: $font-scale-core * 5;            // 40
$font-scale-5: $font-scale-core * 4;            // 32
$font-scale-6: $font-scale-core * 3.5;          // 28
$font-scale-7: $font-scale-core * 3;            // 24
$font-scale-8: $font-scale-core * 2.5;          // 20
$font-scale-9: $font-scale-core * 2;            // 16
$font-scale-10: $font-scale-core * 1.5;         // 12
$font-scale-11: $font-scale-core * 1;           // 8


// Lineheight scale
$line-height-core: 8px;
$line-height-1: $font-scale-core * 13 * 1.8; // $line-height-core * 13 + 6;     // 110  187.2 
$line-height-2: $font-scale-core * 9.5 * 1.8; // $line-height-core * 9.5 + 6;    // 82  136.8
$line-height-3: $font-scale-core * 8 * 1.8; // $line-height-core * 8 + 6;      // 70    115.2
$line-height-4: $font-scale-core * 4.5 * 1.8; // $line-height-core * 6 + 6;      // 54   64.8
$line-height-5: $font-scale-core * 4 * 1.8; // $line-height-core * 4.5 + 6;    // 42     57.6
$line-height-6: $font-scale-core * 3.5 * 1.8; // $line-height-core * 6 + 6;      // 54   50.4
$line-height-7: $font-scale-core * 3 * 1.8; // $line-height-core * 3.5 + 6;    // 34     43.2
$line-height-8: $font-scale-core * 2.5 * 1.8; // $line-height-core * 3.5 + 6;    // 34   36.0
$line-height-9: $font-scale-core * 2 * 1.8; // $line-height-core * 2.5 + 6;    // 26     28.8
$line-height-10: $font-scale-core * 1.5 * 1.8; // $line-height-core * 1.5 + 6;   // 18   21.6
$line-height-11: $font-scale-core * 1 * 1.8; // $line-height-core * 1 + 6;     // 14     14.4


// Letterspacing
$letter-space-tight: -1px;
$letter-space-extraclose: -0.25px;
$letter-space-close: -0.12px;
$letter-space-normal: 0;
$letter-space-cozy: 0.12px;
$letter-space-loose: 0.25px;
$letter-space-extraloose: 0.50px;


// Colors
$color-brand: #000000;
$color-background: #FFFFFF;
$color-text-primary: #222222;
$color-text-secondary: #888888;
$color-negative: #CF0000;


// Spacing
$spacing-core: 16px;
$spacing-xxxs: $spacing-core * 0.125;           // 2
$spacing-xxs: $spacing-core * 0.25;             // 4
$spacing-xs: $spacing-core * 0.50;              // 8
$spacing-sm: $spacing-core * 0.75;              // 12
$spacing-md: $spacing-core * 1;                 // 16
$spacing-lg: $spacing-core * 1.50;              // 24
$spacing-xl: $spacing-core * 2;                 // 32
$spacing-xxl: $spacing-core * 2.50;             // 40
$spacing-xxxl: $spacing-core * 3;               // 48


// Layout
$layout-core: 16px;
$layout-xxs: $layout-core * 1;                  // 16
$layout-xs: $layout-core * 1.50;                // 24
$layout-sm: $layout-core * 2;                   // 32
$layout-md: $layout-core * 3;                   // 48
$layout-lg: $layout-core * 4;                   // 64
$layout-xl: $layout-core * 6;                   // 96
$layout-xxl: $layout-core * 10;                 // 160
$layout-xxxl: $layout-core * 15;                // 240


// Border Width
$border-width-core: 2px;
$border-width-hairline: $border-width-core * 0.5;
$border-width-narrow: $border-width-core;
$border-width-thick: $border-width-core * 2;


// Elevation
$elevation-low: 0 0 0 1px rgba(0,0,0,0.08), 0 1px 2px rgba(0, 0, 0, 0.2);
$elevation-medium: 0 4px 8px rgba(0,0,0,0.15);
$elevation-high: 0 10px 16px rgba(0,0,0,0.18), 0 2px 6px rgba(0,0,0,0.2);
$elevation-higher: 0 16px 24px rgba(0,0,0,0.20);
$elevation-highest: 0 0 30px rgba(0,0,0,0.10);

// Border Radius
$border-radius-core: 4px;
$border-radius-sharp: 0;
$border-radius-subtle: $border-radius-core * 0.50;
$border-radius-normal: $border-radius-core * 1;
$border-radius-blunt: $border-radius-core * 2;
$border-radius-round: $border-radius-core * 5;
$border-radius-circle: 50%;


// Icon Size
$icon-core: 16px;
$icon-xs: $icon-core * 0.5;         // 8
$icon-sm: $icon-core * 1;           // 16
$icon-md: $icon-core * 1.5;         // 24
$icon-lg: $icon-core * 2;           // 32
$icon-xl: $icon-core * 3;           // 48
$icon-xxl: $icon-core * 4;          // 64
$icon-xxxl: $icon-core * 6;         // 96


// Transition Time
$transition-fast: 200ms;
$transition-normal: 300ms;
$transition-slow: 600ms;


// Breakpoints
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1500px;
$xs-min-width: $breakpoint-xs;
$xs-max-width: $breakpoint-sm - 1;
$sm-min-width: $breakpoint-sm;
$sm-max-width: $breakpoint-md - 1;
$md-min-width: $breakpoint-md;
$md-max-width: $breakpoint-lg - 1;
$lg-min-width: $breakpoint-lg;
$lg-max-width: $breakpoint-xl - 1;
$xl-min-width: $breakpoint-xl;
$xl-max-width: $breakpoint-xxl - 1;
$xxl-min-width: $breakpoint-xxl;
$xxl-max-width: auto;
