@import './../../style/_theme.scss';
@import './../../style/_mixins.scss';

// Modal
.Modal {
  &__Wrapper {
    background-color: var(--modal-wrapper);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
  }

  &__Box {
    width: calc(100% - 64px);
    max-width: 640px;
    height: auto;
    max-height: calc(100% - 32px);
    background-color: var(--card-background);
    box-shadow: var(--card-elevation);
    padding: 32px 0;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;

    @media (max-width: $breakpoint-sm) {
      width: calc(100% - 32px);
      padding: 24px 0;
    }
  }

  &__Content {
    width: auto;
    max-height: 400px;
    overflow-y: scroll;
    position: relative;
    padding: 0 32px;

    p {
      max-width: none;
      text-align: left;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (max-width: $breakpoint-sm) {
      padding: 0 24px;
    }
  }

  &__Close {
    transition: width $transition-fast cubic-bezier(.25,.8,.25,1), background-color $transition-fast cubic-bezier(.25,.8,.25,1);
    width: 32px;
    height: 32px;
    box-shadow: var(--floating-control-elevation);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    top: -16px;
    right: -16px;
    font-size: 24px;
    user-select: none;
    cursor: pointer;
    background-color: var(--control-background-active);
    color: var(--control-text-active);

    &:hover {
      background-color: var(--control-background);
      color: var(--control-text);
    }

    @media (max-width: $breakpoint-sm) {
      right: 0;
    }
  }

  &__Trigger {
    transition: box-shadow $transition-fast ease-in-out, transform $transition-fast ease-in-out;
    background-color: var(--control-background-active);
    position: fixed;
    overflow: hidden;
    top: 12px;
    right: 12px;
    width: 62px;
    height: 62px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% 8px 50% 50%;
    box-shadow: var(--card-elevation);
    transform: scale(1);

    &::after {
      content: 'i';
      width: 30px;
      height: 30px;
      border: 2px solid var(--control-text-active);
      border-radius: 50%;
      text-align: center;
      font-family: serif;
      font-size: 22px;
      font-weight: bold;
      font-style: italic;
      line-height: 30px;
      color: var(--control-text-active);
    }

    &:hover {
      box-shadow: none;
      transform: scale(0.9);
    }
  }
}
