@import './../../style/_theme.scss';
@import './../../style/_mixins.scss';

.Controls {
  width: 100%;
  max-width: 1500px;
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  &__Wrapper {
    transition: all 0.1s ease-in-out;
    width: 100%;
    max-width: 1500px;
    height: auto;
    max-height: 220px;
    overflow-y: auto;
    margin: 0 auto;
    background-color: var(--card-background);
    box-shadow: var(--card-elevation);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 48px;
    border-radius: 10px 10px 0 0;
    padding: 24px 0 40px 0;

    &__Hidden {
      max-height: 20px;
      padding: 24px 0 0 0;

      .Controls__Block {
        opacity: 0;
      }
    }
  }

  &__Block {
    transition: all 0.1s ease-in-out;
    display: inline-flex;
    flex-direction: column;
    box-shadow: var(--control-block);
    padding: 0 24px 0 24px;
    display: block;

    &:first-child {
      box-shadow: none;
    }
  }
  
  &__Label {
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    margin-bottom: 16px;
    display: block;
    text-transform: uppercase;
    width: 100%;
    display: block;
    color: var(--site-text-alt);
  }

  &__Slider {
    width: 160px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  &__RadioGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 8px;
  }

  &__Radio {
    display: block;
    height: 32px;
    width: 32px;
    border: none;
    position: relative;
    margin-right: 8px;
    cursor: pointer;

    &:last-child {
      margin: 0;
    }

    &__Label {
      transition: all 0.1s ease-in-out;
      border-radius: 50%;
      background-color: var(--control-background);
      text-align: center;
      line-height: 34px;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      user-select: none;
    }

    &:hover & {
      &__Label {
        background-color: var(--control-background-active);
        color: var(--control-text-active);
        box-shadow: var(--control-elevation);
      }
    }

    input[type=radio] {
      display: none;
    }

    input:checked ~ span {
      background-color: var(--control-background-active);
      color: var(--control-text-active);
      box-shadow: var(--control-elevation);
    }
  }

  &__Radio--Reset {
    order: 100;
    margin-right: 0;
    margin-left: 8px;

    .Controls__Radio__Label {
      background-color: transparent;
      font-size: 24px;
      font-weight: 300;
      color: var(--control-destructive);

      &:hover {
        background-color: var(--control-destructive);
      }
    }

    input:checked ~ span {
      background-color: transparent;
      box-shadow: none;
      color: var(--control-destructive);
    }
  }

  &__Color {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 8px;
    line-height: 0;

    & > * {
      margin-right: 8px;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__PickerClose{
    transition: 
      width $transition-fast cubic-bezier(.25,.8,.25,1), 
      background-color $transition-fast cubic-bezier(.25,.8,.25,1);
    width: 32px;
    height: 32px;
    box-shadow: var(--floating-control-elevation);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    top: -16px;
    right: -16px;
    font-size: 24px;
    background-color: var(--control-background-active);
    color: var(--control-text-active);
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: var(--control-background);
      color: var(--control-text);
    }
  }

  &__Swatch {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0;
    border: 6px solid var(--control-background);
    display: inline-block;
    cursor: pointer;
    box-sizing: border-box;
  }

  &__Button {
    transition: all 0.1s ease-in-out;
    border-radius: 50px;
    height: 32px;
    width: auto;
    border: none;
    text-align: center;
    line-height: 32px;
    background-color: var(--control-background);
    color: var(--site-text);
    font-size: 12px;
    font-weight: 600;
    padding: 0 12px;
    line-height: 32px;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    vertical-align: top;
    display: inline;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: var(--site-text);
      color: var(--control-background);
      box-shadow: var(--control-elevation);
    }
  }

  &__ButtonInverted {
    transition: all 0.1s ease-in-out;
    border-radius: 50px;
    height: 32px;
    width: auto;
    border: none;
    text-align: center;
    line-height: 32px;
    background-color: var(--site-text);
    color: var(--control-background);
    font-size: 12px;
    font-weight: 600;
    padding: 0 12px;
    line-height: 32px;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    vertical-align: top;
    display: inline;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: var(--control-background);
      color: var(--site-text);
      box-shadow: var(--control-elevation);
    }
  }

  &__SwatchWrapper {
    position: relative;
    display: inline-block;
  }

  &__Popover {
    position: absolute;
    z-index: 2;
    left: 0;
    top: -200px;
    line-height: 24px;
  }

  &__Colapse {
    transition: 
      width $transition-fast cubic-bezier(.25,.8,.25,1), 
      background-color $transition-fast cubic-bezier(.25,.8,.25,1), 
      color $transition-fast cubic-bezier(.25,.8,.25,1);
    width: 40px;
    height: 40px;
    box-shadow: var(--floating-control-elevation);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    top: -20px;
    right: 12px;
    font-size: 26px;
    background-color: var(--control-background-active);
    color: var(--control-text-active);
    user-select: none;
    cursor: pointer;
    z-index: 1;

    &:hover {
      background-color: var(--control-background);
      color: var(--control-text);
    }
  }

  &__Cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}


// RC Slider
.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: var(--control-background);
  height: 4px;
  border-radius: 6px;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: var(--site-text);
}

.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: none;
  background-color: var(--site-text);
  touch-action: pan-x;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: var(--control-elevation);
}

.rc-slider-handle:focus {
  outline: none;
}

.rc-slider-handle-click-focused:focus {
  box-shadow: unset;
}

.rc-slider-handle:active {
  box-shadow: var(--control-elevation);
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.chrome-picker {
  background-color: var(--card-background) !important;

  input {
    color: var(--control-text) !important;
    background-color: var(--card-background) !important;
  }
}
