// ----------------------------------------------------------------
// MIXINS
// Tools and general reusable styles
// ----------------------------------------------------------------


// Tokens Import
@import './_theme.scss';


// Card
@mixin card() {
  border-radius: $border-radius-normal;
  box-shadow: $elevation-medium;
  background-color: $color-background;
  transition: all $transition-slow cubic-bezier(.25,.8,.25,1);
  overflow: hidden;
  position: relative;

  a {
    display: block;
    line-height: 0;
  }

  &:hover {
    box-shadow: $elevation-high;
    transform: translateY(-8px);
  }
}


// Text - Display
@mixin text-display {
  font-family: $font-title;
  font-size: $font-scale-2;
  line-height: $line-height-2;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-space-tight;
  text-transform: uppercase;

  @media (max-width: $breakpoint-md) {
    font-size: $font-scale-4;
    line-height: $line-height-4;
    letter-spacing: $letter-space-extraclose;
  }
}

// Text - Heading LG
@mixin text-heading-lg {
  font-family: $font-title;
  font-size: $font-scale-4;
  line-height: $line-height-4;
  font-weight: $font-weight-light;
  letter-spacing: $letter-space-extraclose;

  @media (max-width: $breakpoint-md) {
    font-size: $font-scale-7;
    line-height: $line-height-7 - 10px;
    letter-spacing: $letter-space-close;
  }
}

// Text - Heading MD
@mixin text-heading-md {
  font-family: $font-title;
  font-weight: $font-weight-regular;
  font-size: $font-scale-6;
  line-height: $line-height-6;
  letter-spacing: $letter-space-loose;

  @media (max-width: $breakpoint-md) {
    font-size: $font-scale-8;
    line-height: $line-height-8;
    letter-spacing: $letter-space-close;
  }
}

// Text - Heading SM
@mixin text-heading-sm {
  font-family: $font-title;
  font-size: $font-scale-9;
  line-height: $line-height-9;
  font-weight: $font-weight-semibold;
  letter-spacing: $letter-space-cozy;
  text-transform: uppercase;
}

// Text - Body LG
@mixin text-body-lg {
  font-family: $font-text;
  font-size: $font-scale-6;
  line-height: $line-height-6;
  font-weight: $font-weight-light;
  letter-spacing: $letter-space-cozy;

  @media (max-width: $breakpoint-md) {
    font-size: $font-scale-7;
    line-height: $line-height-7;
    letter-spacing: $letter-space-loose;
  }

  @media (max-width: $breakpoint-sm) {
    font-size: $font-scale-9;
    line-height: $line-height-9; //
    letter-spacing: $letter-space-loose;
  }  
}

// Text - Body MD
@mixin text-body-md {
  font-family: $font-text;
  font-size: $font-scale-7;
  line-height: $line-height-7;
  font-weight: $font-weight-light;
  letter-spacing: $letter-space-loose;
  
  @media (max-width: $breakpoint-md) {
    font-size: $font-scale-8;
    line-height: $line-height-8;
    letter-spacing: $letter-space-normal;
  }
}

// Text - Body SM
@mixin text-body-sm {
  font-family: $font-text;
  font-size: $font-scale-8;
  line-height: $line-height-8;
  font-weight: $font-weight-regular;
  letter-spacing: $letter-space-cozy;

  @media (max-width: $breakpoint-md) {
    font-size: $font-scale-9;
    line-height: $line-height-9;
    letter-spacing: $letter-space-normal;
  }
}

// Text - Body XS
@mixin text-body-xs {
  font-family: $font-text;
  font-size: $font-scale-9;
  line-height: $line-height-9;
  font-weight: $font-weight-regular;
  letter-spacing: $letter-space-cozy;

  @media (max-width: $breakpoint-md) {
    font-size: $font-scale-10;
    line-height: $line-height-10;
    letter-spacing: $letter-space-normal;
  }
}

// Text - Detail 1 - Legend
@mixin text-detail-legend {
  font-family: $font-text;
  font-size: $font-scale-10;
  line-height: $line-height-10;
  font-weight: $font-weight-semibold;
  letter-spacing: $letter-space-extraloose;
  text-transform: uppercase;
}

// Text - Detail 2 - Accent
@mixin text-detail-accent {
  font-family: $font-accent;
  font-size: $font-scale-7;
  line-height: $line-height-7 - 10px;
  font-weight: $font-weight-regular;
  font-style: oblique;
  letter-spacing: $letter-space-extraloose;

  @media (max-width: $breakpoint-md) {
    font-size: $font-scale-8;
    line-height: $line-height-8 - 8px;
    letter-spacing: $letter-space-loose;
  }
}

// Text - Detail 3 - Rank
@mixin text-detail-rank {
  font-family: $font-accent;
  font-size: $font-scale-8;
  line-height: $line-height-8;
  font-weight: $font-weight-regular;
  letter-spacing: $letter-space-normal;
}
